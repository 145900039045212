import React from "react";

// export const TABLET = 769;
export const TABLET = 922;

export interface Breakpoints {
  mobile(): boolean;
  touchScreen(): boolean;
  width: number;
  height: number;
}

export const useBreakpoints = (): Breakpoints => {
  const [width, setWidth] = React.useState<number>(window.innerWidth);
  const [height, setHeight] = React.useState<number>(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return React.useMemo(
    () => ({
      mobile: () => width < TABLET,
      touchScreen: () => width < TABLET,
      width,
      height,
    }),
    [width, height]
  );
};
