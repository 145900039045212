import React from "react";
import "./App.scss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CompanyWebsiteClientContext } from "./client_contexts";
import { CompanyWebsiteClient } from "./clients/company_website_client";
import { APP_CONFIG } from "./config";
import "./App.scss";
import { Main } from "./Main";
import { Careers } from "./Careers";
import { Container } from "./Container";
import { Contact } from "./Contact";

const ROUTER = createBrowserRouter(
  [
    {
      path: "/",
      element: <Container />,
      children: [
        {
          path: "/careers",
          element: <Careers />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        // {
        //   index: true,
        //   element: <Main />,
        // },
        {
          path: "/:slug?",
          // index: true,
          element: <Main />,
        },
      ],
    },
  ],
  { basename: window.APP_CONFIG.basename }
);

export const App: React.FC<{}> = () => {
  const companyWebsiteClient = React.useMemo(() => {
    return new CompanyWebsiteClient(APP_CONFIG.backend_url);
  }, []);

  return (
    <CompanyWebsiteClientContext.Provider value={companyWebsiteClient}>
      <RouterProvider router={ROUTER} />
    </CompanyWebsiteClientContext.Provider>
  );
};
