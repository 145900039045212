import React from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompanyWebsiteClientContext } from "./client_contexts";
import classNames from "classnames";
import contact from "./contact.svg";
import "./Contact.scss";

interface FormValues {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export const Contact: React.FC<{}> = () => {
  const client = React.useContext(CompanyWebsiteClientContext);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormValues>();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "instant" as any,
    });
  }, []);

  const submit = handleSubmit(async (data) => {
    try {
      await client.sendContactRequest({
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
      });
    } catch (err: any) {
      enqueueSnackbar(
        "Could not send message. Please try again later or send an email directly to contact@pythialabs.com",
        { variant: "error", autoHideDuration: 5000 }
      );
      return;
    }
    enqueueSnackbar(
      "We got your message! We will get back to you as soon as possible.",
      { variant: "success", autoHideDuration: 5000 }
    );
  });

  return (
    <>
      <div
        style={{ marginTop: "6rem", marginLeft: "2rem", marginRight: "2rem" }}
      >
        <img src={contact} className="contact-illustration" />
        <h1
          className="title is-1"
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            textAlign: "center",
            marginBottom: "4rem",
          }}
        >
          Drop us a line!
        </h1>
        <form
          onSubmit={submit}
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            padding: "1.5rem",
            background: "hsl(0, 0%, 98%)",
            borderRadius: "1rem",
          }}
        >
          <div className="field">
            <label className="label">Name</label>
            <div className="control has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="Write your name here"
                required
                {...register("name", { required: true })}
              />
              <span className="icon is-left">
                <FontAwesomeIcon icon={faUser} />
              </span>
            </div>
            <p className="help is-danger">{errors.name?.message}</p>
          </div>
          <div className="field">
            <label className="label">Email Address</label>
            <div className="control has-icons-left">
              <input
                className="input"
                type="email"
                placeholder="Let us know how to contact you back"
                required
                {...register("email", { required: "Field required." })}
              />
              <span className="icon is-left">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
            </div>
            <p className="help is-danger">{errors.email?.message}</p>
          </div>
          <div className="field">
            <label className="label">Subject</label>
            <div className="control">
              <input
                className="input"
                type="text"
                required
                {...register("subject", { required: "Field required." })}
              />
            </div>
            <p className="help is-danger">{errors.email?.message}</p>
          </div>
          <div className="field">
            <label className="label">Message</label>
            <div className="control">
              <textarea
                className="textarea"
                {...register("message", { required: "Field required." })}
              ></textarea>
            </div>
            <p className="help is-danger">{errors.message?.message}</p>
          </div>
          <div className="field">
            <div className="control">
              <button
                className={classNames("button is-rounded is-primary", {
                  "is-loading": isSubmitting,
                })}
                style={{ marginTop: "2rem" }}
                type="submit"
              >
                Send Message
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
