export function assert(
  condition: boolean,
  message?: string | (() => string)
): asserts condition {
  if (!condition) {
    let messageStr: string;
    if (!message) {
      messageStr = "assertion error";
    } else if (message instanceof Function) {
      messageStr = `${message()}`; // Cast to string just in case
    } else {
      messageStr = `${message}`; // Cast to string just in case
    }
    throw new Error(messageStr);
  }
}

export function unknownType(object: never): never {
  throw new Error(`unknown type ${object}`);
}
