import React from "react";
import { Outlet } from "react-router";
import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material";
import { Menu } from "./Menu";
import { NavigationContext } from "./navigation";
import { Footer } from "./Footer";
import { ScrollRestoration } from "react-router-dom";

const style = getComputedStyle(document.body);

const getColor = (cssVariable: string) =>
  style.getPropertyValue(cssVariable).trim().replace("deg", "");

const MUI_THEME = createTheme({
  typography: {
    fontFamily: ['"Quicksand"', "sans-serif"].join(","),
  },
  palette: {
    primary: { main: getColor("--primary") },
    error: { main: getColor("--danger") },
    warning: { main: getColor("--warning") },
    info: { main: getColor("--info") },
    success: { main: getColor("--success") },
  },
});

export const Container: React.FC<{}> = () => {
  const [contactFormOpened, setContactFormOpened] = React.useState(false);
  const [miniNavbar, setMiniNavbar] = React.useState(false);

  return (
    <ThemeProvider theme={MUI_THEME}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <NavigationContext.Provider value={{ miniNavbar, setMiniNavbar }}>
          {/* <ScrollRestoration /> */}
          <Menu />

          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <Outlet />
              {/* <button
        className="contact"
        onClick={() => setContactFormOpened(!contactFormOpened)}
      >
        <svg className="contact-icon" viewBox="-10 -11 40 40">
          <path d="M17.211,3.39H2.788c-0.22,0-0.4,0.18-0.4,0.4v9.614c0,0.221,0.181,0.402,0.4,0.402h3.206v2.402c0,0.363,0.429,0.533,0.683,0.285l2.72-2.688h7.814c0.221,0,0.401-0.182,0.401-0.402V3.79C17.612,3.569,17.432,3.39,17.211,3.39M16.811,13.004H9.232c-0.106,0-0.206,0.043-0.282,0.117L6.795,15.25v-1.846c0-0.219-0.18-0.4-0.401-0.4H3.189V4.19h13.622V13.004z"></path>
        </svg>
      </button> */}
              {/* <ContactForm contactFormOpened={contactFormOpened} /> */}
            </div>
            <Footer />
          </div>
        </NavigationContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
