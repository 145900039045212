import React from "react";

const ScrollMagicControllerContext = React.createContext<any>(null);

export const ScrollMagicControllerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const controller = React.useMemo(
    () => new (window as any).ScrollMagic.Controller(),
    []
  );
  return (
    <ScrollMagicControllerContext.Provider value={controller}>
      {children}
    </ScrollMagicControllerContext.Provider>
  );
};

export const useScrollMagicController = () => {
  const context = React.useContext(ScrollMagicControllerContext);

  React.useEffect(() => {
    return () => {
      context.destroy();
    };
  }, []);

  return context;
};
