import { assert } from "@pythia/util_ts/src/assert";
import React from "react";
// import "./CanvasBg.scss";

export const CanvasBg: React.FC<{}> = () => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const canvasAnimated = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (canvasRef.current == null || canvasAnimated.current) {
      return;
    }

    const canvas = canvasRef.current;

    var baseURL = "../img/binder-anim/";

    const context = canvas.getContext("2d", { alpha: false });
    assert(context != null);

    context.fillStyle = "#fff";
    context.font = "24px Quicksand, Roboto, Tahoma, sans-serif";
    context.fillText("Loading...", 15, 40);
    const sprite = {
      frame: 0,
      lastFrame: -1,
      totalFrames: 13,
      textures: [],
    };

    var animation = new (window as any).TimelineMax({
      repeat: 0,
      repeatDelay: 1,
      yoyo: false,
      paused: true,
      onUpdate: drawSprite,
    }).to(sprite, 1, {
      frame: sprite.totalFrames - 1,
      roundProps: "frame",
      ease: (window as any).Linear.easeNone,
    });

    loadTextures(sprite.totalFrames)
      .then(resizeCanvas)
      .catch(function (reason) {
        console.log(reason);
      });

    function drawSprite() {
      // No changes
      if (sprite.frame === sprite.lastFrame) {
        return;
      }

      assert(context != null);
      context.drawImage(sprite.textures[sprite.frame], 0, 0);
      sprite.lastFrame = sprite.frame;
    }

    function resizeCanvas(textures: any) {
      var texture = textures[0];
      sprite.textures = textures;

      const height = texture.naturalHeight || texture.height;
      const width = texture.naturalWidth || texture.width;
      const innerHeight = window.innerHeight > 600 ? window.innerHeight : 600;
      if (innerHeight / window.innerWidth < height / width) {
        canvas.width = width;
        canvas.height = (innerHeight / window.innerWidth) * width;
      } else {
        canvas.width = (window.innerWidth / innerHeight) * height;
        canvas.height = height;
      }
      canvas.classList.add("is-loaded");
      canvas.classList.add("active");
      canvas.classList.add("stop");

      // We're ready to go!
      animation.play();
    }

    function loadTextures(numTextures: number) {
      var promises = [];

      for (var i = 0; i < numTextures; i++) {
        var index = i < 10 ? "0" + i : i;

        // loadTexture returns a promise. It resolves when image is loaded
        promises.push(loadTexture(baseURL + "binder-anim" + index + ".jpg"));
      }

      // Resolves when all the promises are resolved
      return Promise.all(promises);
    }

    function loadTexture(path: string) {
      return new Promise(function (resolve, reject) {
        var img = new Image();

        img.onload = function () {
          resolve(img);
        };

        img.onerror = function () {
          reject("Error loading " + path);
        };

        img.src = path;
      });
    }

    canvasAnimated.current = true;
  }, [canvasRef.current]);

  return <canvas id="canvas_bg" ref={canvasRef}></canvas>;
};
