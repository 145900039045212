import { BaseClient } from "./base_client";

export interface ContactRequest {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export interface Application {
  position_title: string;
  name: string;
  email: string;
  phone_number: string;
  resume: string;
  cover_letter: string;
}

export interface NewsletterSubscriptionRequest {
  email: string;
}

export class CompanyWebsiteClient extends BaseClient {
  async sendContactRequest(
    contactRequest: ContactRequest,
    abortController?: AbortController | undefined
  ): Promise<void> {
    return this.httpPost("/contact_request", contactRequest, abortController, {
      retry: true,
    });
  }

  async sendApplication(
    application: Application,
    abortController?: AbortController | undefined
  ): Promise<void> {
    return this.httpPost("/application", application, abortController, {
      retry: true,
    });
  }

  async subscribeToNewsletter(
    newsletterSubscriptionRequest: NewsletterSubscriptionRequest,
    abortController?: AbortController | undefined
  ): Promise<void> {
    return this.httpPost(
      "/newsletter",
      newsletterSubscriptionRequest,
      abortController,
      {
        retry: true,
      }
    );
  }
}
