import { assert } from "./assert";

export const sleep = async function (
  ms: number,
  abortController?: AbortController
) {
  await new Promise<void>((resolve) => {
    let handleAbort: () => void;
    const id = setTimeout(() => {
      if (abortController !== undefined) {
        assert(handleAbort !== undefined);
        abortController.signal.removeEventListener("abort", handleAbort);
      }
      resolve();
    }, ms);
    if (abortController !== undefined) {
      handleAbort = () => {
        clearTimeout(id);
        resolve();
      };
      abortController.signal.addEventListener("abort", handleAbort);
    }
  });
};

export const sleepUntilVisible = async function (
  abortController?: AbortController
) {
  if (document.hidden) {
    console.log("hidden");
    await new Promise<void>((resolve) => {
      const handleVisibilityChange = () => {
        console.log("handleVisibilityChange");
        if (!document.hidden) {
          console.log("not hidden");
          document.removeEventListener(
            "visibilitychange",
            handleVisibilityChange
          );
          resolve();
        }
      };
      document.addEventListener(
        "visibilitychange",
        handleVisibilityChange,
        false
      );
      if (abortController) {
        abortController.signal.addEventListener("abort", () => {
          console.log("aborted here");
          document.removeEventListener(
            "visibilitychange",
            handleVisibilityChange
          );
          resolve();
        });
      }
    });
  }
};

export const POLL_LARGE_INTERVAL_MS = 10_000;
export const POLL_MEDIUM_INTERVAL_MS = 4_000;
export const POLL_DEFAULT_INTERVAL_MS = 1_000;

export const poll = (
  abortController: AbortController,
  func: () => Promise<void>,
  intervalMs: number = POLL_DEFAULT_INTERVAL_MS
) => {
  async function run() {
    try {
      while (!abortController.signal.aborted) {
        await func();
        await sleep(intervalMs, abortController);
        await sleepUntilVisible(abortController);
        console.log("again");
      }
    } catch (err: any) {
      if (err.name !== "AbortError") {
        throw err;
      }
      console.log("aborted");
    }
  }
  run();
};
