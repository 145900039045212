import { assert } from "@pythia/util_ts/src/assert";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import {
  faBriefcase,
  faUser,
  faEnvelope,
  faPhone,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompanyWebsiteClientContext } from "./client_contexts";

interface FormValues {
  position_title: string;
  name: string;
  email: string;
  phone_number: string;
  resume: string;
  cover_letter: string;
}

export const CareersForm = React.forwardRef<HTMLDivElement, { job: string }>(
  ({ job }, ref) => {
    const client = React.useContext(CompanyWebsiteClientContext);
    const { enqueueSnackbar } = useSnackbar();
    const {
      register,
      handleSubmit,
      formState: { isSubmitting, errors },
      setValue,
    } = useForm<FormValues>();

    React.useEffect(() => {
      setValue("position_title", job);
    }, [job]);

    React.useEffect(() => {
      register("resume", { required: true });
    }, [register]);

    const handleResumeChange = async (files: FileList | null) => {
      assert(files != null && files.length === 1);
      const file = files[0];

      const base64 = await convertBase64(file);
      setValue("resume", base64);
    };

    const submit = handleSubmit(async (data) => {
      try {
        await client.sendApplication(data);
      } catch (err: any) {
        enqueueSnackbar(
          "Could not send application. Please try again later or send an email directly to contact@pythialabs.com",
          { variant: "error", autoHideDuration: 5000 }
        );
        return;
      }
      enqueueSnackbar(
        "Application successfully sent! We will get back to you as soon as possible.",
        { variant: "success", autoHideDuration: 5000 }
      );
    });

    return (
      <div style={{ marginLeft: "2rem", marginRight: "2rem" }}>
        <h1
          ref={ref}
          className="title is-1"
          style={{
            paddingTop: "6rem",
            textAlign: "center",
            marginBottom: "4rem",
          }}
        >
          Send an Application
        </h1>
        <form
          onSubmit={submit}
          style={{
            maxWidth: "800px",
            margin: "0 auto",
            padding: "1.5rem",
            background: "hsl(0, 0%, 98%)",
            borderRadius: "1rem",
          }}
        >
          <div className="field">
            <label className="label">Position Applied For</label>
            <div className="control has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="Enter the position you are applying for"
                {...register("position_title")}
              />
              <span className="icon is-left">
                <FontAwesomeIcon icon={faBriefcase} />
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">Full Name</label>
            <div className="control has-icons-left">
              <input
                className="input"
                type="text"
                placeholder="Enter your full name"
                {...register("name", { required: "Field required." })}
              />
              <span className="icon is-left">
                <FontAwesomeIcon icon={faUser} />
              </span>
            </div>
            <p className="help is-danger">{errors.name?.message}</p>
          </div>
          <div className="field">
            <label className="label">Email Address</label>
            <div className="control has-icons-left">
              <input
                className="input"
                type="email"
                placeholder="Enter your email address"
                {...register("email", { required: "Field required." })}
              />
              <span className="icon is-left">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
            </div>
            <p className="help is-danger">{errors.email?.message}</p>
          </div>
          <div className="field">
            <label className="label">Phone Number</label>
            <div className="control has-icons-left">
              <input
                className="input"
                type="tel"
                placeholder="Enter your phone number"
                {...register("phone_number", { required: "Field required." })}
              />
              <span className="icon is-left">
                <FontAwesomeIcon icon={faPhone} />
              </span>
            </div>
            <p className="help is-danger">{errors.phone_number?.message}</p>
          </div>
          <div className="field">
            <label className="label">Resume</label>
            <div className="control has-icons-left">
              <input
                className="input"
                type="file"
                accept="application/pdf"
                onChange={(e) => handleResumeChange(e.target.files)}
              />
              <span className="icon is-left">
                <FontAwesomeIcon icon={faFile} />
              </span>
            </div>
          </div>
          <div className="field">
            <label className="label">Cover Letter</label>
            <div className="control">
              <textarea
                className="textarea"
                placeholder="Write your cover letter."
                {...register("cover_letter")}
              ></textarea>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button
                className={classNames("button is-rounded is-primary", {
                  "is-loading": isSubmitting,
                })}
                style={{ marginTop: "2rem" }}
                type="submit"
              >
                Submit Application
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
);

async function convertBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      assert(typeof fileReader.result === "string");
      assert(/^data:[^;]+;base64,/.test(fileReader.result));
      resolve(fileReader.result.substring(fileReader.result.indexOf(",") + 1));
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
