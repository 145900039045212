import React from "react";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { CompanyWebsiteClientContext } from "./client_contexts";
import classNames from "classnames";

interface FormValues {
  email: string;
}

export const NewsletterForm: React.FC<{}> = () => {
  const client = React.useContext(CompanyWebsiteClientContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormValues>();
  const { enqueueSnackbar } = useSnackbar();

  const submit = handleSubmit(async (data) => {
    try {
      await client.subscribeToNewsletter(data);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(
        "We are currently unable to add you to the subscription list.  Please try later.",
        { variant: "error", autoHideDuration: 5000 }
      );
      return;
    }
    enqueueSnackbar("You're now subscribed to Pythia's updates!", {
      variant: "success",
      autoHideDuration: 5000,
    });
  });

  return (
    <form
      onSubmit={submit}
      style={{ marginBottom: "2.5rem", marginLeft: "calc(1em + 1px)" }}
    >
      <label className="label is-small">Sign up for our newsletter</label>
      <div className="field has-addons">
        <p className="control">
          <input
            className="input is-small"
            type="text"
            placeholder="Email"
            style={{ width: "300px", maxWidth: "calc(100vw - 200px)" }}
            {...register("email", { required: true })}
          />
        </p>
        <p className="control">
          <button
            className={classNames("button is-info is-small", {
              "is-loading": isSubmitting,
            })}
            type="submit"
          >
            Submit
          </button>
        </p>
        <p className="help is-danger">{errors.email?.message}</p>
      </div>
    </form>
  );
};
