import classNames from "classnames";

export const JobContainer: React.FC<{
  jobTitle: string;
  jobDescriptionShort: React.ReactNode;
  items: React.ReactNode[];
  jobDetails: React.ReactNode;
  onToggleJobDetails: () => void;
  jobDetailsOpen: boolean;
  onApply: (jobTitle: string) => void;
}> = ({
  jobTitle,
  jobDescriptionShort,
  items,
  jobDetails,
  onApply,
  onToggleJobDetails,
  jobDetailsOpen,
}) => {
  return (
    <article
      style={{ marginLeft: "2rem", marginRight: "2rem", marginBottom: "3rem" }}
    >
      <div
        style={{
          margin: "0 auto",
          maxWidth: "800px",
          padding: "1.5rem",
          borderRadius: "1rem",
          background: "hsl(0, 0%, 98%)",
        }}
      >
        <h3 className="title is-3">{jobTitle}</h3>
        <div className="subtitle is-4">{jobDescriptionShort}</div>
        <div className="tags block">
          {items.map((item, index) => (
            <li className="tag is-rounded is-primary is-light" key={index}>
              {item}
            </li>
          ))}
        </div>
        <div className="buttons" style={{ marginBottom: 0 }}>
          <button
            onClick={() => onApply(jobTitle)}
            className="button is-primary is-rounded"
          >
            Apply
          </button>
          <button
            onClick={() => onToggleJobDetails()}
            className="button is-dark is-outlined is-rounded"
          >
            {!jobDetailsOpen ? "View Details" : "Hide Details"}
          </button>
        </div>
        <div
          style={{
            display: jobDetailsOpen ? "block" : "none",
            marginTop: "20px",
            padding: "1rem",
            borderRadius: "1rem",
            background: "white",
            border: "1px solid hsl(0, 0%, 86%)",
          }}
          className="content"
        >
          {jobDetails}
        </div>
      </div>
    </article>
  );
};
