import { faChevronDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pythiaBlackText from "./img/Pythia_black_text.svg";
import pythiaBlackPictoOuter from "./img/Pythia_black_picto_outer.svg";
import pythiaBlackPictoInner from "./img/Pythia_black_picto_inner.svg";
import scrollDown from "./img/scroll-down.json";
import { CanvasBg } from "./CanvasBg";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import "./Hero.scss";

export const Hero = React.forwardRef<
  HTMLDivElement,
  { onLogoTop?: (top: boolean) => void }
>(({ onLogoTop }, ref) => {
  const navigate = useNavigate();
  const logoRef = React.useRef<HTMLDivElement>(null);
  // We do not do dynamic updates, and that's on purpose
  // See https://nicolas-hoizey.com/articles/2015/02/18/viewport-height-is-taller-than-the-visible-part-of-the-document-in-some-mobile-browsers/#february-23rd-update
  // for the quirks of viewports on mobile.
  const initialHeight = React.useMemo(() => window.innerHeight, []);

  React.useEffect(() => {
    if (logoRef?.current == null) return;
    const observer = new IntersectionObserver(
      (e) => {
        onLogoTop?.(e[0].intersectionRatio < 1);
      },
      {
        rootMargin: "-1px 0px 0px 0px",
        threshold: [1],
      }
    );

    observer.observe(logoRef.current);

    return () => {
      observer.disconnect();
    };
  }, [logoRef]);

  return (
    <section
      ref={ref}
      className="main-hero"
      style={{ height: initialHeight <= 600 ? undefined : initialHeight }}
    >
      <CanvasBg />
      <div id="main">
        <div className="logo">
          <div className="logo_text" ref={logoRef}>
            <img src={pythiaBlackText} />
          </div>
          <div className="pythia-loader">
            <div className="outer_icon">
              <img src={pythiaBlackPictoOuter} />
            </div>
            <div className="inner_icon">
              <img src={pythiaBlackPictoInner} />
            </div>
          </div>
          <h1>Generating the Molecules of Tomorrow</h1>
          <a
            href="https://demo.pythialabs.com"
            className="button is-primary is-large is-rounded demo-link"
            target="_blank"
          >
            <span style={{ minWidth: "150px" }}>DEMO</span>
            <span className="icon">
              <FontAwesomeIcon icon={faArrowRight} size="lg" />
            </span>
          </a>
        </div>
      </div>
      <div className="hero-button-container">
        <button
          onClick={() => navigate("/presentation")}
          className="button is-white hero-button"
        >
          <FontAwesomeIcon icon={faChevronDown} beat />
        </button>
      </div>
    </section>
  );
});
