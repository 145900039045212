import React from "react";
import LottiePlayer, { LottieRefCurrentProps, LottieRef } from "lottie-react";
import dbAnimation from "./img/DB_animation.json";
import platformAnimation from "./img/platform_animation.json";
import scalableAnimation from "./img/scalable.json";
import physicsAnimation from "./img/physics_animation.json";
import tubeAnimation from "./img/tube_animation.json";
import aiCore from "./img/AI_core.png";
import flask from "./flask.svg";
import scalableSystem from "./scalable-system.svg";
import molecule from "./molecule.svg";
import useOf from "./use-of-artificial-intelligence-and-internet-technologies.svg";
// import dataServer from "./data-server-branch-network.svg";
import dataServer from "./test.svg";
import scienceResearch from "./science-research.svg";
import "./Technology.scss";

const PlatformItem: React.FC<{
  icon: string;
  title: string;
  children?: React.ReactNode;
}> = ({ icon, title, children }) => {
  return (
    <div style={{ marginBottom: "2rem" }}>
      <div className="technology-section-inner">
        <div style={{ flex: "0 0 30%" }}></div>
        <div className="platform-content">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.75rem",
              marginBottom: "0.75rem",
            }}
          >
            <div style={{ flex: "0 0 3rem", width: "3rem", height: "3rem" }}>
              <img src={icon} style={{ width: "3rem" }} />
            </div>
            <h3 className="title is-5" style={{ verticalAlign: "middle" }}>
              {title}
            </h3>
          </div>

          <div className="content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export const Technology = React.forwardRef<HTMLDivElement, {}>((_, ref) => {
  const scalableAnimationRef = React.useRef<LottieRefCurrentProps>();

  React.useEffect(() => {
    if (scalableAnimationRef.current) {
      scalableAnimationRef.current.setSpeed(0.5);
    }
  }, [scalableAnimationRef.current]);

  return (
    <div className="technology">
      <h1
        ref={ref}
        className="title is-1"
        style={{
          textAlign: "center",
          marginBottom: "4rem",
          paddingTop: "6rem",
        }}
      >
        Technology
      </h1>
      <section className="technology-section">
        <div className="technology-section-inner">
          <div style={{ flex: "0 0 30%" }}>
            <img src={useOf} className="illustration" />
          </div>
          <div
            style={{
              background: "hsl(0, 0%, 98%)",
              borderRadius: "2rem",
              padding: "2rem",
            }}
          >
            <h2 className="title is-3">Deep Learning at the core</h2>
            <p>
              We harness the power of cutting-edge deep learning techniques and
              draw inspiration from diverse fields such as physics, quantum
              mechanics, and mathematics to develop innovative model
              architectures that are tailored for biological applications,
              enabling us to achieve unparalleled breakthroughs in biomolecular
              design.
            </p>
          </div>
        </div>
      </section>
      <section className="technology-section">
        <div className="technology-section-inner technology-section-inner-reverse">
          <div
            style={{
              background: "hsl(0, 0%, 98%)",
              borderRadius: "2rem",
              padding: "2rem",
            }}
          >
            <h2 className="title is-2 data-assemble">Data.assemble()</h2>
            <p style={{ maxWidth: "700px" }}>
              Pythia's proprietary data pipeline processes massive amounts of
              biological data. It automatically assembles high-quality datasets
              providing the necessary foundation to train our models.
            </p>
          </div>
          <div style={{ flex: "0 0 30%" }}>
            <img src={scienceResearch} className="illustration" />
          </div>
        </div>
      </section>
      <section className="technology-section vertical-correction">
        <div className="technology-section-inner">
          <div style={{ flex: "0 0 30%" }}>
            <img src={dataServer} className="illustration" />
          </div>
          <div
            style={{
              background: "hsl(0, 0%, 98%)",
              borderRadius: "2rem",
              padding: "2rem",
            }}
          >
            <h2 className="title is-3">A next-gen platform for next-gen AI </h2>
            <p style={{ maxWidth: "700px" }}>
              We believe that a world-class infrastructure is essential to
              unleash the full potential of our AI models, which is why we are
              creating a next-gen platform to enhance their capabilities.
            </p>
          </div>
        </div>
      </section>
      <section className="technology-section vertical-correction2">
        <div>
          <PlatformItem icon={scalableSystem} title="Scalable by Design">
            We designed a platform that can harness the power of thousands of
            GPUs concurrently, allowing our models to operate effortlessly at
            scale.
          </PlatformItem>
          <PlatformItem
            icon={molecule}
            title="Created with AI, Grounded in Biophysics"
          >
            The platform includes a range of safety measures and tools that
            guarantee the quality of our AI-generated outputs from a biophysical
            standpoint.
          </PlatformItem>
          <PlatformItem icon={flask} title="Beyond Therapeutics">
            The modular nature of our platform allows for a wide range of
            applications. As we continue to push the boundaries of AI and
            computational biology, we strive to solve problems beyond
            therapeutics and have a lasting impact on fields such as
            diagnostics, agriculture, energy and sustainability.
          </PlatformItem>
        </div>
      </section>
    </div>
  );
});
