import React from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CareersForm } from "./CareersForm";
import { JobContainer } from "./JobContainer";
import testTube from "./test-tube-cropped.jpg";
import "./Careers.scss";

export const Careers: React.FC<{}> = () => {
  const [job, setJob] = React.useState("");
  const [activeJobDetails, setActiveJobDetails] =
    React.useState<string | null>(null);
  const positionsRef = React.createRef<HTMLDivElement>();
  const formRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "instant" as any,
    });
  }, []);

  const scroll = () => {
    if (positionsRef.current) {
      positionsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onApply = (jobTitle: string) => {
    setJob(jobTitle);
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleActiveJobDetails = (name: string) => {
    if (activeJobDetails === name) {
      setActiveJobDetails(null);
    } else {
      setActiveJobDetails(name);
    }
  };

  return (
    <>
      <section className="careers-hero">
        <div className="vertical-aligner">
          <div className="inner">
            <img src={testTube} className="illustration" />
            <h1 className="title is-1">
              JOIN US TO SHAPE THE MOLECULES OF TOMORROW
            </h1>
            <h2 className="subtitle is-4">
              As we enter the golden age of AI in biology, our mission is to
              accelerate the discovery and engineering of next-generation
              therapeutics. Come help us redefine and shape the future of
              biomolecules!
            </h2>
          </div>
        </div>
        <div className="hero-button-container">
          <button
            onClick={scroll}
            className="button is-white hero-button"
            style={{ display: "block", margin: "0 auto" }}
          >
            <FontAwesomeIcon icon={faChevronDown} beat />
          </button>
        </div>
      </section>
      <div>
        <div>
          <h1
            ref={positionsRef}
            className="title is-1"
            style={{
              paddingTop: "6rem",
              textAlign: "center",
              marginBottom: "4rem",
            }}
          >
            Available Positions
          </h1>
          <JobContainer
            jobTitle="Data Engineer"
            jobDescriptionShort={
              <>
                Join our Product & Engineering team where you will be using your
                expertise to turn research into production-ready code.
              </>
            }
            items={[
              "+3 years of experience",
              "Data pipelines",
              "Bioinformatics tools",
              "MLOps",
              "Cloud Computing",
              "Python",
              "US-authorized",
            ]}
            jobDetails={
              <>
                <h3>Your impact</h3>
                <p>
                  You will be part of our team of Research Scientists and
                  Engineers and will be using your technical expertise to
                  design, build, test, optimize, and maintain the pipelines that
                  integrate our cutting-edge bioinformatics tools and feed our
                  deep learning models.
                </p>
                <h3>What you will do</h3>
                <p>
                  <ul>
                    <li>
                      Use our data platform to develop, maintain and test
                      complex data pipelines that process terabytes of data
                    </li>
                    <li>
                      Collaborate closely with the Computational Biology team to
                      turn research into production-ready code
                    </li>
                    <li>
                      Implement and optimize algorithms efficiently, scale them,
                      and integrate them into larger systems
                    </li>
                    <li>
                      Identify bottlenecks and performance issues in existing
                      pipelines and propose and implement solutions for
                      optimization
                    </li>
                    <li>
                      Ensure that the data we use is well-structured, versioned,
                      reliable, and discoverable
                    </li>
                  </ul>
                </p>
                <h3>Who we are looking for</h3>

                <h4>Essential Qualifications</h4>

                <p>
                  <ul>
                    <li>
                      MSc with at least 3 years of professional experience in
                      Software Engineering, Computer Science, Bioinformatics, or
                      Computational Biology (but do not hesitate to apply if you
                      think you're the right fit)
                    </li>
                    <li>
                      Demonstrated ability to build robust applications capable
                      of handling complex, large volumes of data
                    </li>
                    <li>
                      Experience with Amazon Web Services (AWS) or Google Cloud
                      Platform (GCP)
                    </li>
                    <li>
                      Proficiency in Python and data analysis tools such as
                      Numpy, Pandas and Jupyter notebooks
                    </li>
                    <li>
                      Experience with containerization solutions such as Docker
                    </li>
                    <li>
                      Experience with workflow orchestration systems such as
                      Argo Workflow, Kubeflow, Snakemake, Airflow, Prefect, or
                      Dagster
                    </li>
                    <li>
                      Effective written and oral communication skills and the
                      ability to present to technical and non-technical
                      audiences
                    </li>
                    <li>Must be authorized to work in the U.S.</li>
                  </ul>
                </p>

                <h4>Nice to have</h4>

                <p>
                  <ul>
                    <li>
                      Experience with bioinformatics tools such as MMseqs,
                      FoldSEEK, AlphaFold, ESMFold
                    </li>
                    <li>
                      Experience with computational biology libraries such as
                      BioPython
                    </li>
                    <li>
                      Experience with scientific computing libraries such as
                      SciPy and scikit-learn
                    </li>
                    <li>
                      Experience with machine learning frameworks such as
                      PyTorch or Tensorflow
                    </li>
                    <li>Experience with GPU-based code optimization</li>
                  </ul>
                </p>

                <h4>Personal</h4>
                <p>
                  <ul>
                    <li>
                      Team player, willingness to collaborate and communicate
                      with other team members
                    </li>
                    <li>
                      Passionate, highly self-motivated and able to operate with
                      significant autonomy
                    </li>
                    <li>Healthy skepticism and detail-oriented</li>
                    <li>
                      You define yourself as a generalist problem solver, not by
                      the technologies that you know
                    </li>
                    <li>Willingness to keep updated on new technologies</li>
                    <li>An urge to automate everything 🙂</li>
                  </ul>
                </p>

                <h3>A few reasons to join us</h3>
                <p>
                  <ul>
                    <li>
                      Innovate at the frontier of Computational Biology and AI
                    </li>
                    <li>
                      Solve important scientific problems for the benefit of
                      humanity
                    </li>
                    <li>
                      Develop impactful technology that has real-world
                      applications
                    </li>
                    <li>Be part of an exceptional interdisciplinary team</li>
                    <li>World Renowned Scientific Advisory Board</li>
                    <li>Opportunity for growth</li>
                    <li>Hybrid (home/office) work is encouraged</li>
                    <li>Work/life balance</li>
                    <li>
                      Attractive location close to a lot of amenities and
                      restaurants in the heart of Culver City
                    </li>
                    <li>Diverse and international team</li>
                    <li>
                      Hybrid High Performance Computing infrastructure on AWS
                      and on premises, with state-of-the-art GPU servers,
                      terabytes of data and a trove of interesting engineering
                      challenges to tackle
                    </li>
                  </ul>
                </p>
                <p>
                  <h3>Strong compensation package</h3>
                  <ul>
                    <li>A competitive base salary (D.O.E)</li>
                    <li>Excellent medical, dental and health benefits</li>
                    <li>Excellent 401K retirement plan</li>
                  </ul>
                </p>
              </>
            }
            onToggleJobDetails={() => toggleActiveJobDetails("dataeng")}
            jobDetailsOpen={activeJobDetails === "dataeng"}
            onApply={onApply}
          />
          <JobContainer
            jobTitle="Software Engineer"
            jobDescriptionShort={
              <>
                Join our Product & Engineering team where you will be using your
                expertise to develop our advanced data platform and cutting-edge
                software suite.
              </>
            }
            items={[
              "+3 years of experience",
              "Backend Development",
              "DevOps",
              "MLOps",
              "Cloud Computing",
              "Python",
              "US-authorized",
            ]}
            jobDetails={
              <>
                <h3>Your impact</h3>
                <p>
                  You will be part of our team of Research Scientists and
                  Engineers and will be using your technical expertise to
                  develop our advanced data platform and cutting-edge software
                  suite that have far reaching applications in Biotechnology.
                </p>
                <h3>What you will do</h3>
                <p>
                  <ul>
                    <li>
                      Build an advanced platform to facilitate state-of-the-art
                      AI research in biology by automating an entire data
                      curation pipeline, optimizing distributed model training,
                      and developing cost-effective inference capabilities
                    </li>
                    <li>
                      Collaborate with experts in Deep Learning, Computational
                      Biology and Software Engineering to build products aimed
                      at reducing the time and cost required to develop novel
                      therapeutics
                    </li>
                  </ul>
                </p>
                <h3>Who we are looking for</h3>

                <h4>Essential Qualifications</h4>

                <p>
                  <ul>
                    <li>
                      MSc with at least 3 years of professional experience in
                      Software Engineering (but do not hesitate to apply if you
                      think you're the right fit)
                    </li>
                    <li>
                      Experience with Amazon Web Services (AWS) or Google Cloud
                      Platform (GCP)
                    </li>
                    <li>
                      Experience in one of the following areas: containerization
                      solutions such as Docker, infrastructure-as-a-code
                      solutions such as Terraform, backend development (API
                      documentation and implementation, authentication and
                      authorization)
                    </li>
                    <li>Proficiency in Python</li>
                    <li>
                      Effective written and oral communication skills and the
                      ability to present to technical and non-technical
                      audiences
                    </li>
                    <li>Must be authorized to work in the U.S.</li>
                  </ul>
                </p>

                <h4>Nice to have</h4>

                <p>
                  <ul>
                    <li>
                      Experience with ML Ops, machine learning frameworks
                      (PyTorch, Tensorflow, …), GPU frameworks (e.g., CUDA)
                    </li>
                    <li>
                      Experience with container orchestration systems such as
                      Kubernetes
                    </li>
                    <li>
                      Experience with workflow orchestration systems such as
                      Argo Workflow
                    </li>
                  </ul>
                </p>

                <h4>Personal</h4>
                <p>
                  <ul>
                    <li>
                      Team player, willingness to collaborate and communicate
                      with other team members
                    </li>
                    <li>
                      Passionate, highly self-motivated and able to operate with
                      significant autonomy
                    </li>
                    <li>Healthy skepticism and detail-oriented</li>
                    <li>
                      You define yourself as a generalist problem solver, not by
                      the technologies that you know
                    </li>
                    <li>Willingness to keep updated on new technologies</li>
                    <li>An urge to automate everything 🙂</li>
                  </ul>
                </p>

                <h3>A few reasons to join us</h3>
                <p>
                  <ul>
                    <li>
                      Innovate at the frontier of Computational Biology and AI
                    </li>
                    <li>
                      Solve important scientific problems for the benefit of
                      humanity
                    </li>
                    <li>
                      Develop impactful technology that has real-world
                      applications
                    </li>
                    <li>Be part of an exceptional interdisciplinary team</li>
                    <li>World Renowned Scientific Advisory Board</li>
                    <li>Opportunity for growth</li>
                    <li>Hybrid (home/office) work is encouraged</li>
                    <li>Work/life balance</li>
                    <li>
                      Attractive location close to a lot of amenities and
                      restaurants in the heart of Culver City
                    </li>
                    <li>Diverse and international team</li>
                    <li>
                      Hybrid High Performance Computing infrastructure on AWS
                      and on premises, with state-of-the-art GPU servers,
                      terabytes of data and a trove of interesting engineering
                      challenges to tackle
                    </li>
                  </ul>
                </p>
                <p>
                  <h3>Strong compensation package</h3>
                  <ul>
                    <li>A competitive base salary (D.O.E)</li>
                    <li>Excellent medical, dental and health benefits</li>
                    <li>Excellent 401K retirement plan</li>
                  </ul>
                </p>
              </>
            }
            onToggleJobDetails={() => toggleActiveJobDetails("swe")}
            jobDetailsOpen={activeJobDetails === "swe"}
            onApply={onApply}
          />
          <JobContainer
            jobTitle="Research scientist in AI"
            jobDescriptionShort={
              <>
                Join our team of Research Scientists where you will be using
                your expertise in AI and Computer Science to develop our
                next-gen platform.
              </>
            }
            items={[
              "+2 years AI",
              "Pytorch",
              "Graph Neural Networks",
              "Transformers",
              "Generative AI",
              "US-authorized",
            ]}
            jobDetails={
              <>
                <h3>Your impact</h3>
                <p>
                  Are you looking to solve complex biological problems using
                  cutting-edge fundamental and applied AI research? Do you want
                  to make a difference in the field of Drug Development? Come
                  and join us! You will be part of our team of Research
                  Scientists and will be using your technical expertise to
                  develop our software suite that has a wide range of
                  applications in Biotechnology.
                </p>
                <h3>What you will do</h3>
                <p>
                  <ul>
                    <li>
                      Apply state-of-the-art Deep Learning approaches to solve
                      long-standing problems in Biology and Drug Development
                    </li>
                    <li>
                      Develop novel Deep Learning architectures that power the
                      core of our software
                    </li>
                    <li>
                      Work with complex biological data to train and evaluate
                      Neural Networks
                    </li>
                    <li>
                      Collaborate with experts in Deep Learning, Computational
                      Biology, Computational Chemistry and Software Engineering
                    </li>
                    <li>
                      Build highly sophisticated models to reduce the time and
                      cost required to develop novel therapeutics
                    </li>
                  </ul>
                </p>
                <h3>Who we are looking for</h3>

                <h4>Essential Qualifications</h4>

                <p>
                  <ul>
                    <li>
                      PhD or MSc with at least 2 years of professional
                      experience in Computer Science, Mathematics, Data Science,
                      Statistics, Physics or other quantitative disciplines
                    </li>
                    <li>
                      Strong background in Mathematics with focus in disciplines
                      relevant to AI
                    </li>
                    <li>
                      Formal training in Deep Learning and experience with two
                      or more of the following: Graph Neural Networks,
                      Convolutional Neural Networks, Transformers, Transfer
                      Learning, Natural Language Processing, Generative Neural
                      Networks
                    </li>
                    <li>
                      Proven record of productivity, and independence in the
                      field of Deep Learning as evidenced by scientific
                      publications or publicly available projects
                    </li>
                    <li>
                      Proficiency with Python and Machine Learning frameworks
                      (eg. PyTorch or TensorFlow)
                    </li>
                    <li>
                      Effective written and oral communication skills and the
                      ability to present to technical and non-technical
                      audiences
                    </li>
                    <li>Comfortable with Linux</li>
                    <li>Must be authorized to work in the U.S.</li>
                  </ul>
                </p>

                <h4>Nice to have</h4>

                <p>
                  <ul>
                    <li>Experience with biomolecular data</li>
                    <li>Experience with version control tools</li>
                    <li>Experience with noSQL databases</li>
                    <li>Experience with DevOps tools (eg. Docker, Cloud)</li>
                    <li>Experience with GPU frameworks (eg. CUDA)</li>
                    <li>Familiarity with Group Theory</li>
                    <li>Peer reviewed publications</li>
                  </ul>
                </p>

                <h4>Personal</h4>
                <p>
                  <ul>
                    <li>
                      Team player, willingness to collaborate and communicate
                      with other team members
                    </li>
                    <li>
                      Passionate, highly self-motivated and able to operate with
                      significant autonomy
                    </li>
                    <li>
                      Scientific rigor, healthy skepticism, and detail-oriented
                    </li>
                  </ul>
                </p>

                <h3>A few reasons to join us</h3>
                <p>
                  <ul>
                    <li>
                      Innovate at the frontier of Computational Biology and AI
                    </li>
                    <li>
                      Solve important scientific problems for the benefit of
                      humanity
                    </li>
                    <li>
                      Develop impactful technology that has real-world
                      applications
                    </li>
                    <li>
                      Be part of an interdisciplinary team of exceptionally
                      talented individuals
                    </li>
                    <li>World Renowned Scientific Advisory Board</li>
                    <li>Opportunity for growth</li>
                    <li>Hybrid on-premises / remote work is encouraged</li>
                    <li>Work/life balance</li>
                    <li>
                      Attractive location close to a lot of amenities and
                      restaurants in the heart of Culver City
                    </li>
                    <li>Diverse and international team</li>
                    <li>State of the art GPU servers on premises</li>
                  </ul>
                </p>
                <p>
                  <h3>Strong compensation package</h3>
                  <ul>
                    <li>A competitive base salary (D.O.E)</li>
                    <li>Excellent medical, dental and health benefits</li>
                    <li>Excellent 401K retirement plan</li>
                  </ul>
                </p>
              </>
            }
            onToggleJobDetails={() => toggleActiveJobDetails("ai")}
            jobDetailsOpen={activeJobDetails === "ai"}
            onApply={onApply}
          />
          <JobContainer
            jobTitle="Research scientist in Computational Biology"
            jobDescriptionShort={
              <>
                We are hiring a Computational Biologist to expand our Bio team
                and work on cutting-edge fundamental and applied Computational
                Biology research.
              </>
            }
            items={[
              "+4 years Comp. Biology",
              "Ph.D / MSc",
              "Structural Biology",
              "Protein Biophysics",
              "Protein Design",
              "Python",
              "US-authorized",
            ]}
            jobDetails={
              <>
                <h3>Your impact</h3>
                <p>
                  Are you looking to solve complex biological problems using
                  cutting-edge fundamental and applied Computational Biology
                  research? Do you want to make a difference in the field of
                  Drug Development? Come and join us! You will be part of our
                  team of Research Scientists and will be using your technical
                  expertise to develop our platform that has a wide range of
                  applications in Biotechnology.
                </p>
                <h3>What you will do</h3>
                <p>
                  <ul>
                    <li>
                      Develop groundbreaking computational tools aimed at
                      reducing the time and cost required to develop novel
                      therapeutics
                    </li>
                    <li>
                      Build automated data processing pipelines that generate
                      high value proprietary datasets used to train
                      state-of-the-art AI models
                    </li>
                    <li>
                      Collaborate with experts in Deep Learning, Computational
                      Biology, Computational Chemistry and Software Engineering
                    </li>
                    <li>
                      Devise innovative approaches to solve long standing
                      problems in Biology
                    </li>
                    <li>
                      Organize and present data to support decisions in
                      cross-functional teams
                    </li>
                  </ul>
                </p>
                <h3>Who we are looking for</h3>

                <h3>Essential Qualifications</h3>

                <p>
                  <ul>
                    <li>
                      PhD or MSc with 4-5 years of professional experience in
                      Computational Biology, Bioinformatics, Computational
                      Protein Design, Structural Biology or related fields
                    </li>
                    <li>
                      Solid theoretical background in Biophysics and Structural
                      Biology
                    </li>
                    <li>
                      Experience in two or more of the following areas: Protein
                      Design, Molecular Modeling, Docking, Stability, Solubility
                      or Affinity predictions
                    </li>
                    <li>Experience with standard bioinformatics tools</li>
                    <li>
                      Proven record of productivity, scientific creativity and
                      independence in the field of Protein Sciences as evidenced
                      by scientific publications and external presentations
                    </li>
                    <li>
                      Proficiency with Python and relevant scientific libraries
                      (eg. Biopython, SciPy, NumPy)
                    </li>
                    <li>
                      Effective written and oral communication skills and the
                      ability to present to technical and non-technical
                      audiences
                    </li>
                    <li>Comfortable with Linux</li>
                    <li>Must be authorized to work in the U.S.</li>
                  </ul>
                </p>

                <h3>Nice to have</h3>

                <p>
                  <ul>
                    <li>
                      Experience with modern AI or ML approaches such as
                      AlphaFold
                    </li>
                    <li>Experience with multiprocessing libraries</li>
                    <li>Experience with version control tools</li>
                    <li>Familiarity with noSQL databases</li>
                    <li>Familiarity with dockerization concepts</li>
                    <li>Peer reviewed publications</li>
                  </ul>
                </p>

                <h3>Personal</h3>
                <p>
                  <ul>
                    <li>
                      Team player, willingness to collaborate and communicate
                      with other team members
                    </li>
                    <li>
                      Passionate, highly self-motivated and able to operate with
                      significant autonomy
                    </li>
                    <li>
                      Scientific rigor, healthy skepticism, and detail-oriented
                    </li>
                    <li>Pragmatic and output-focused</li>
                  </ul>
                </p>

                <h3>A few reasons to join us</h3>
                <p>
                  <ul>
                    <li>
                      Innovate at the frontier of Computational Biology and AI
                    </li>
                    <li>
                      Solve important scientific problems for the benefit of
                      humanity
                    </li>
                    <li>
                      Develop impactful technology that has real-world
                      applications
                    </li>
                    <li>
                      Be part of an interdisciplinary team of exceptionally
                      talented individuals
                    </li>
                    <li>World Renowned Scientific Advisory Board</li>
                    <li>Opportunity for growth</li>
                    <li>Hybrid on-premises / remote work is encouraged</li>
                    <li>Work/life balance</li>
                    <li>
                      Attractive location close to a lot of amenities and
                      restaurants in the heart of Culver City
                    </li>
                    <li>Diverse and international team</li>
                    <li>State of the art GPU servers on premises</li>
                  </ul>
                </p>
                <p>
                  <h3>Strong compensation package</h3>
                  <ul>
                    <li>A competitive base salary (D.O.E)</li>
                    <li>Excellent medical, dental and health benefits</li>
                    <li>Excellent 401K retirement plan</li>
                  </ul>
                </p>
              </>
            }
            onToggleJobDetails={() => toggleActiveJobDetails("bio")}
            jobDetailsOpen={activeJobDetails === "bio"}
            onApply={onApply}
          />
        </div>
      </div>
      <CareersForm job={job} ref={formRef} />
    </>
  );
};
