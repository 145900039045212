import React from "react";
import { Presentation } from "./Presentation";
import { ScrollMagicControllerProvider } from "./scroll_magic";
import { Technology } from "./Technology";
import { useLocation, useNavigate } from "react-router";
import { Hero } from "./Hero";
import { People } from "./People";
import { Footer } from "./Footer";
import { NavigationContext } from "./navigation";

export const Main: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const heroRef = React.createRef<HTMLDivElement>();
  const presentationRef = React.createRef<HTMLDivElement>();
  const technologyRef = React.createRef<HTMLDivElement>();
  const peopleRef = React.createRef<HTMLDivElement>();
  const { setMiniNavbar } = React.useContext(NavigationContext);
  const [suspend, setSuspend] = React.useState(false);

  React.useEffect(() => {
    console.log("SUSPEND", suspend);
    if (suspend || location.state?.disableScrolling) {
      return;
    }

    // Wait until we are finished scrolling
    let scrollTimeout = setTimeout(function () {
      console.log("HERE");
      window.removeEventListener("scroll", onScroll);
      setSuspend(false);
    }, 100);
    setSuspend(true);
    const onScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(function () {
        console.log("HERE");
        window.removeEventListener("scroll", onScroll);
        setSuspend(false);
      }, 100);
    };
    window.addEventListener("scroll", onScroll);
    console.log("SCROLL TO", location.pathname);

    switch (location.pathname) {
      case "/presentation":
        presentationRef.current?.scrollIntoView();
        // setActive("/presentation");
        break;

      case "/technology":
        technologyRef.current?.scrollIntoView();
        // setActive("/technology");
        break;

      case "/team":
        console.log("SCROLL TO TEAM", peopleRef.current);
        peopleRef.current?.scrollIntoView();
        // setActive("/team");
        break;

      case "/":
        heroRef.current?.scrollIntoView();
        // setActive(null);
        break;
    }

    return () => {
      // window.removeEventListener('scroll', onScroll);
    };
  }, [location.pathname]);

  React.useEffect(() => {
    if (technologyRef.current == null) return;
    if (presentationRef.current == null) return;
    if (peopleRef.current == null) return;

    const observer = new IntersectionObserver(
      () => {
        if (suspend) return;
        const positions = {
          "/presentation": presentationRef.current?.offsetTop,
          "/technology": technologyRef.current?.offsetTop,
          "/team": peopleRef.current?.offsetTop,
        };
        let nextPathname = "/";
        for (const [pathname, offsetTop] of Object.entries(positions)) {
          // console.log(pathname, offsetTop, window.scrollY);
          if (offsetTop != null && offsetTop - 100 < window.scrollY) {
            nextPathname = pathname;
          }
        }
        if (nextPathname !== location.pathname) {
          navigate(nextPathname, { state: { disableScrolling: true } });
          // console.log("Navigate", nextPathname);
          // window.history.replaceState(null, document.title, nextPathname);
          // setActive(nextPathname);
        }
      },
      {
        rootMargin: "-100px",
        threshold: [0, 0.25, 0.5, 0.95, 1],
      }
    );

    observer.observe(presentationRef.current);
    observer.observe(technologyRef.current);
    observer.observe(peopleRef.current);

    return () => {
      // console.log("DISC", location);
      observer.disconnect();
    };
  }, [suspend, location, technologyRef, presentationRef, peopleRef]);

  return (
    <ScrollMagicControllerProvider>
      <Hero ref={heroRef} onLogoTop={(top) => setMiniNavbar(top)} />
      <Presentation ref={presentationRef} />
      <Technology ref={technologyRef} />
      <People ref={peopleRef} />
    </ScrollMagicControllerProvider>
  );
};
