import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faSquareTwitter,
} from "@fortawesome/free-brands-svg-icons";
import "./Footer.scss";
import { NewsletterForm } from "./NewsletterForm";

export const Footer = () => {
  return (
    <div
      style={{
        marginTop: "3rem",
        padding: "3rem 0",
        background: "hsl(0, 0%, 98%)",
      }}
    >
      <div className="footer-columns">
        <div style={{ color: "hsl(0, 0%, 71%)" }}>
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src="/img/Pythia_black_picto_inner.svg"
              height="28"
              width="28"
              alt=""
              style={{ marginRight: "10px" }}
            />
            <img
              src="/img/Pythia_black_text.svg"
              width="112"
              height="28"
              alt=""
              style={{ marginRight: "10px" }}
            />
            <a
              href="https://www.linkedin.com/company/pythialabs/"
              target="_blank"
            >
              <span className="icon is-large">
                <FontAwesomeIcon icon={faLinkedin as any} size="3x" />
              </span>
            </a>
            <a href="https://twitter.com/PythiaLabs" target="_blank">
              <span className="icon is-large">
                <FontAwesomeIcon icon={faSquareTwitter as any} size="3x" />
              </span>
            </a>
          </div>
          <div>
            &copy; 2023 Pythia Labs, Inc.
            <br />
            <br />
            8800 Venice Blvd
            <br />
            Los Angeles, CA 90034
          </div>
        </div>
        <div style={{ marginLeft: "calc(-1em - 1px)" }}>
          <NewsletterForm />
          <ul
            className="menu-list footer-menu-list"
            style={{ width: "fit-content", minWidth: "150px" }}
          >
            <li>
              <a
                href="/privacy_notice.html"
                style={{ borderRadius: "9999px", padding: "0.5em 1em" }}
                target="_blank"
              >
                Privacy Notice
              </a>
            </li>
            <li>
              <a
                href="/disclaimer.html"
                style={{ borderRadius: "9999px", padding: "0.5em 1em" }}
                target="_blank"
              >
                Disclaimer
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
