import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

// const root = createRoot(document.getElementById("root")!);
const root = createRoot(document.body);
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
