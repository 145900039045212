import React from "react";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "./routes";
import pythiaBlackText from "./img/Pythia_black_text.svg";
import classNames from "classnames";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { NavigationContext } from "./navigation";
import { useBreakpoints } from "./use_breakpoints";
import "./Menu.scss";
import backgroundImg from "./background-spot.svg";

export const Menu: React.FC<{}> = () => {
  const location = useLocation();
  const { miniNavbar } = React.useContext(NavigationContext);
  const [navOpened, setNavOpened] = React.useState(false);
  const { mobile } = useBreakpoints();
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (menuRef.current == null) {
      clearAllBodyScrollLocks();
    } else {
      if (navOpened) {
        disableBodyScroll(menuRef.current);
      } else {
        enableBodyScroll(menuRef.current);
      }
    }
  }, [navOpened, menuRef]);

  if (mobile()) {
    if (!navOpened) {
      return (
        <header className="header" style={{ zIndex: 10 }}>
          <button
            className={classNames("button is-rounded is-pulled-right")}
            style={{
              marginTop: "10px",
              marginRight: "20px",
              border: "1px solid black",
              background:
                miniNavbar ||
                location.pathname === "/careers" ||
                location.pathname === "/contact" ||
                location.pathname === "/data-request"
                  ? undefined
                  : "transparent",
            }}
            onClick={() => setNavOpened(true)}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faBars} size="lg" />
            </span>
            <span>Menu</span>
          </button>
        </header>
      );
    }

    return (
      <div
        ref={menuRef}
        className="mini menu-small"
        style={{
          zIndex: 10,
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background: "white",
        }}
      >
        <img
          src="/img/Pythia_black_picto_inner.svg"
          aria-hidden="true"
          alt=""
          style={{
            opacity: 0.05,
            position: "absolute",
            left: "50%",
            bottom: "1rem",
            transform: "translateX(-50%)",
            width: "calc(100vw - 2rem)",
            height: "auto",
            zIndex: -1000,
          }}
        />
        <button
          className={classNames("button is-rounded is-white")}
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: 1000,
          }}
          onClick={() => setNavOpened(false)}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </span>
          <span>Close</span>
        </button>
        <nav className="menu">
          <ul className="main-menu">
            <li
              className={classNames({
                "is-active": location.pathname === "/presentation",
              })}
              onClick={() => setNavOpened(false)}
            >
              <Link to="/presentation">Mission</Link>
            </li>
            <li
              className={classNames({
                "is-active": location.pathname === "/technology",
              })}
              onClick={() => setNavOpened(false)}
            >
              <Link to="/technology">Technology</Link>
            </li>
            <li
              className={classNames({
                "is-active": location.pathname === "/team",
              })}
              onClick={() => setNavOpened(false)}
            >
              <Link to="/team">Team</Link>
            </li>
            <li
              className={classNames({
                "is-active": location.pathname === "/careers",
              })}
              onClick={() => setNavOpened(false)}
            >
              <Link to={ROUTES.careers()}>Careers</Link>
            </li>
            <li
              className={classNames({
                "is-active": location.pathname === "/contact",
              })}
              onClick={() => setNavOpened(false)}
            >
              <Link to={"/contact"}>Contact us</Link>
            </li>
            {/* Do not close navigation when going to the demo, on purpose (a new tab is opened) */}
            <li className="contact-menu-li">
              <a href="https://demo.pythialabs.com" target="_blank">
                Demo
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }

  return (
    <header
      className={classNames("header", {
        mini:
          miniNavbar ||
          location.pathname === "/careers" ||
          location.pathname === "/contact" ||
          location.pathname === "/data-request",
      })}
      style={{ zIndex: 10 }}
    >
      <Link className="minilogo" to="/">
        <img
          src="/img/Pythia_black_picto_inner.svg"
          height="30"
          width="30"
          alt=""
          style={{ marginRight: "10px" }}
          className="brand"
        />
        <img
          src="/img/Pythia_black_text.svg"
          width="112"
          height="30"
          alt=""
          style={{ marginRight: "10px" }}
          className="text"
        />
      </Link>
      <nav className={classNames("menu")}>
        <ul className="main-menu">
          <li
            className={classNames({
              "is-active": location.pathname === "/presentation",
            })}
          >
            <Link to="/presentation">Mission</Link>
          </li>
          <li
            className={classNames({
              "is-active": location.pathname === "/technology",
            })}
          >
            <Link to="/technology">Technology</Link>
          </li>
          <li
            className={classNames({
              "is-active": location.pathname === "/team",
            })}
          >
            <Link to="/team">Team</Link>
          </li>
          <li
            className={classNames({
              "is-active": location.pathname === "/careers",
            })}
          >
            <Link to={ROUTES.careers()}>Careers</Link>
          </li>
          <li
            className={classNames({
              "is-active": location.pathname === "/contact",
            })}
          >
            <Link to={"/contact"}>Contact us</Link>
          </li>
          <li className="contact-menu-li">
            <a href="https://demo.pythialabs.com" target="_blank">
              Demo
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};
