import { BaseError } from "make-error";
import { sleep } from "@pythia/util_ts/src/time";
import { assert } from "@pythia/util_ts/src/assert";

export class ForbiddenError extends BaseError {}

export class ServiceUnavailableError extends BaseError {}

export type Authorizer = (
  url: string | URL,
  init?: RequestInit
) => Promise<[string | URL, RequestInit | undefined]>;

export class BaseClient {
  constructor(
    private readonly url: string,
    private readonly authorizer?: Authorizer
  ) {}

  protected async httpGet(
    path: string,
    controller?: AbortController
  ): Promise<any> {
    assert(path.startsWith("/"));
    // Loop to retry in case of transient connection errors
    let resp: any;
    while (true) {
      try {
        resp = await this.fetch(`${this.url}${path}`, {
          signal: controller?.signal,
          mode: "cors",
        });
        break;
      } catch (err: any) {
        if (err.name === "AbortError") {
          throw err;
        }
        console.error(err);
        await sleep(1_000, controller);
      }
    }
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    if (resp.status === 503) {
      throw new ServiceUnavailableError();
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    const body = await resp.json();
    return body;
  }

  protected async httpDelete(
    path: string,
    controller?: AbortController
  ): Promise<any> {
    assert(path.startsWith("/"));
    // Loop to retry in case of transient connection errors
    let resp: any;
    while (true) {
      try {
        resp = await this.fetch(`${this.url}${path}`, {
          method: "DELETE",
          signal: controller?.signal,
          mode: "cors",
        });
        break;
      } catch (err: any) {
        if (err.name === "AbortError") {
          throw err;
        }
        console.error(err);
        await sleep(1_000, controller);
      }
    }
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    if (resp.status === 503) {
      throw new ServiceUnavailableError();
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    const body = await resp.json();
    return body;
  }

  protected async httpPost(
    path: string,
    body: any,
    controller?: AbortController,
    options: {
      retry: boolean;
    } = { retry: false }
  ): Promise<any> {
    assert(path.startsWith("/"));
    const json_body = JSON.stringify(body);
    // Loop to retry in case of transient connection errors
    let resp: any;
    while (true) {
      try {
        resp = await this.fetch(`${this.url}${path}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: json_body,
          signal: controller?.signal,
          mode: "cors",
        });
        break;
      } catch (err: any) {
        if (err.name === "AbortError" || !options.retry) {
          throw err;
        }
        console.error(err);
        await sleep(1_000, controller);
      }
    }
    if (resp.status === 403) {
      throw new ForbiddenError();
    }
    if (resp.status === 503) {
      throw new ServiceUnavailableError();
    }
    if (resp.status !== 200) {
      throw new Error(`unexpected status ${resp.status}`);
    }
    const resp_body = await resp.json();
    return resp_body;
  }

  private async fetch(
    url: string | URL,
    init?: RequestInit
  ): Promise<Response> {
    if (this.authorizer) {
      [url, init] = await this.authorizer(url, init);
    }
    return fetch(url, init);
  }
}
