declare global {
  interface Window {
    PYTHIA_ENV: string;
    APP_CONFIG: {
      basename: string;
      backend_url: string;
    };
  }
}

export const PYTHIA_ENV = window.PYTHIA_ENV;
export const APP_CONFIG = window.APP_CONFIG;
