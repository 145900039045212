import React from "react";
import { useScrollMagicController } from "./scroll_magic";
import binder from "./img/binder.jpg";
import "./Presentation.scss";

export const Presentation = React.forwardRef<HTMLDivElement, {}>((_, ref) => {
  return (
    <section ref={ref} className="container presentation">
      <div className="left content">
        <h1>
          Our mission
          <svg className="connector" style={{ position: "absolute" }}>
            <path
              d="M 35 20 H 110 a10,10 0 0 1 10,10 V 95"
              stroke="black"
              strokeDasharray="4"
              fill="transparent"
            />
            <circle cx="20" cy="20" r="8" fill="white" stroke="black" />
            <circle cx="120" cy="100" r="8" fill="white" stroke="black" />
          </svg>
        </h1>
        <p style={{ fontSize: "1.25rem" }}>
          Pythia Labs develops advanced AI and computational biology
          technologies to accelerate the design of innovative therapeutics.
        </p>
      </div>
      <div className="middle">
        <img src={binder} className="illustration" />
      </div>
      <div className="right content">
        <h1>
          <svg
            className="connector"
            style={{ position: "absolute", marginLeft: "-150px" }}
          >
            <path
              d="M 35 20 H 120"
              stroke="black"
              strokeDasharray="4"
              fill="transparent"
            />
            <circle cx="20" cy="20" r="8" fill="white" stroke="black" />
            <circle cx="132" cy="20" r="8" fill="white" stroke="black" />
          </svg>
          Our vision
        </h1>
        <p style={{ fontSize: "1.25rem" }}>
          We are unlocking a future where groundbreaking experiments are
          conducted on a computer, saving precious time in the lab.
        </p>
      </div>
    </section>
  );
});
